/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import "@hotwired/turbo-rails";
const Filters = require("./filters.js");
const Shipment = require("./shipment.js");
const Packages = require("./packages.js");

document.addEventListener("turbo:load", function(event) {
  // Disable Turbo on forms. TODO: migrate forms to Turbo and remove this.
  document.querySelectorAll("form").forEach(function (el) {
    el.dataset.turbo = false;
  });

  const userFilterSettings = {
    form: ".admin.users form.filter",
    results: ".admin.users .results"
  };

  const companyFilterSettings = {
    form: ".admin.companies form.filter",
    results: ".admin.companies .results"
  };

  const shipmentFilterSettings = {
    form: ".shipments form.filter",
    results: ".shipments .results"
  };

  Filters.init(userFilterSettings);
  Filters.init(companyFilterSettings);
  Filters.init(shipmentFilterSettings);
  Shipment.init();
  Packages.init();
});
