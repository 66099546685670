let filters = {};
let t = setTimeout(() => {}, 1000);

const setDate = holder => {
  const input = holder.querySelector('input[type="hidden"]');
  let val = "";

  holder.querySelectorAll("select").forEach((el, i) => {
    val =
      (el.value != "" && String(el.value).length < 2
        ? "0" + el.value
        : el.value) +
      "-" +
      val;
  });

  input.value = /[0-9]/.test(val) ? val.substr(0, val.length - 1) : "";
};

const getParams = (elements, props) => {
  const form = document.querySelector(props.form);
  const re = new RegExp(form.getAttribute("data-model"));

  if (form.querySelector(".date-group"))
    setDate(form.querySelector(".date-group"));

  return (
    Array.prototype.slice
      .call(elements)
      .reduce((acc, el) => {
        if ((re.test(el.name) || /limit/.test(el.name)) && el.value != "")
          acc.push(el.name + "=" + el.value);
        return acc;
      }, [])
      .join("&") + "&ajax=1"
  );
};

const search = (elements, props) => {
  const form = document.querySelector(props.form);
  Rails.ajax({
    url: form.getAttribute("action"),
    type: "get",
    dataType: "html",
    data: getParams(elements, props),
    success: data => {
      document.querySelector(props.results).innerHTML = data.html;
    },
    error: data => {}
  });
};

const load = (elements, props) => {
  clearTimeout(t);

  document.querySelector(props.results).innerHTML = "";
  t = setTimeout(() => {
    search(elements, props);
  }, 1000);
};

const types = {
  input: (el, elements, props) => {
    el.addEventListener("keyup", e => {
      load(elements, props);
    });
  },
  select: (el, elements, props) => {
    el.addEventListener("change", e => {
      load(elements, props);
    });
  }
};
const init = props => {
  const formFilterEl = document.querySelector(props.form);

  if (formFilterEl) {
    const elements = formFilterEl.querySelectorAll("input, select");

    elements.forEach(el => {
      if (types[el.nodeName.toLowerCase()]) {
        types[el.nodeName.toLowerCase()](el, elements, props);
      }
    });
  }
};

module.exports = { init };
