const Helper = require("./helper.js");

const recipient = {};

recipient.setSession = (e, form) => {
  Rails.ajax({
    url: e.pathname,
    type: "get",
    data: Helper.serialize(form) + "&ajax=1",
    success: data => {
      location.href = e.pathname;
    },
    error: data => {}
  });
};

recipient.init = () => {
  const form = document.querySelector(".shipments.recipient form");

  if (form) {
    let backBtns = [".progress-bar-holder li:first-child a", "a.back"];

    for (let selector of backBtns) {
      const el = document.querySelector(selector);
      el.addEventListener("click", e => {
        e.preventDefault();
        recipient.setSession(el, form);
      });
    }
  }
};

const init = () => {
  recipient.init();
};

module.exports = { init };
