const Packages = {};

Packages.orderAction = () => {};

Packages.orderAction.arr = [];
Packages.orderAction.setIdx = (id, dir) => {
  let arr = Packages.orderAction.arr;
  let idx = arr.indexOf(id);
  if (dir == "down") idx++;
  else idx--;

  if (idx >= 0 && idx < arr.length)
    Packages.orderAction.switch(arr.indexOf(id), idx);
};

Packages.orderAction.switch = (current, replacement) => {
  let value = Packages.orderAction.arr[current];
  let valueToReplace = Packages.orderAction.arr[replacement];

  Packages.orderAction.arr[replacement] = value;
  Packages.orderAction.arr[current] = valueToReplace;

  location.href =
    location.pathname +
    "/?do=reorder&ids[]=" +
    Packages.orderAction.arr.join("&ids[]=");
  console.log(Packages.orderAction.arr);
};

Packages.orderAction.init = () => {
  const elements = document.querySelectorAll(".order-arrows span");

  if (elements.length > 0) {
    document.querySelectorAll(".list-item:not(.head)").forEach(el => {
      Packages.orderAction.arr.push(el.getAttribute("data-id"));
    });

    elements.forEach(el => {
      el.addEventListener("click", e => {
        Packages.orderAction.setIdx(
          el.getAttribute("data-id"),
          el.getAttribute("class")
        );
      });
    });
  }
};

Packages.init = () => {
  Packages.orderAction.init();
};

module.exports = Packages;
